import { useState } from 'react';
import AsyncState from '../models/AsyncState';

const initialState = new AsyncState();

const useAsyncState = () => {
  const [state, setState] = useState(initialState);

  return {
    asyncState: state,
    setLoading: () =>
      setState({
        ...state,
        isLoading: true,
        isLoaded: false,
        error: undefined,
      }),
    setLoaded: () =>
      setState({
        ...state,
        isLoading: false,
        isLoaded: true,
        error: undefined,
      }),
    setError: (error: Error) =>
      setState({ ...state, isLoaded: false, isLoading: false, error }),
  };
};

export default useAsyncState;
