import React, { FunctionComponent } from 'react';
import { IComment } from '../../../types';
import { WithStyles, Theme, createStyles, withStyles } from '@material-ui/core';
import Comment from './Comment';
import AddCommentForm from '../../Forms/AddCommentForm';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing.unit * 3,
    },
    comment: {
      '& + *': {
        marginTop: theme.spacing.unit * 2,
      },
    },
  });

interface CommentsProps {
  comments: IComment[];
  gatheringId: string;
}

const Comments: FunctionComponent<CommentsProps & WithStyles> = ({
  classes,
  comments,
  gatheringId,
}) => {
  return (
    <div className={classes.container}>
      {comments.map(comment => (
        <Comment
          className={classes.comment}
          key={comment.id}
          comment={comment}
        />
      ))}
      <AddCommentForm
        form={`add-comment-${gatheringId}`}
        gatheringId={gatheringId}
      />
    </div>
  );
};

export default withStyles(styles)(Comments);
