import React, { FunctionComponent, ComponentType } from 'react';
import cx from 'classnames';
import ConditionalIconButton from '../../display/ConditionalIconButton';
import { AcceptStatus, IUser } from '../../../types';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { acceptGathering } from '../../../services/gatherings';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { colorSuccess } from '../../Theme';
import MobileFriendlyTooltip from '../../display/MobileFriendlyTooltip';

const styles = (theme: Theme) =>
  createStyles({
    isInPast: {
      color: theme.palette.action.disabled,
    },
    [AcceptStatus.Accept]: {
      color: colorSuccess.main,
    },
    [AcceptStatus.Decline]: {
      color: theme.palette.error.main,
    },
    [AcceptStatus.Tentative]: {
      color: theme.palette.primary.light,
    },
  });

const getAcceptStatusTooltip = (acceptStatus: AcceptStatus) => {
  switch (acceptStatus) {
    case AcceptStatus.Accept:
      return "I'm going!";
    case AcceptStatus.Tentative:
      return 'meh...';
    case AcceptStatus.Decline:
    default:
      return "Nah I'm good.";
  }
};

const AcceptStatusIconButton: FunctionComponent<
  {
    Icon: ComponentType<SvgIconProps>;
    userAcceptStatus?: AcceptStatus;
    acceptStatus: AcceptStatus;
    user: IUser;
    gatheringId: string;
    groupId: string;
    setGatheringStatusFn: typeof acceptGathering;
    isInPast: boolean;
  } & WithStyles
> = ({
  classes,
  Icon,
  userAcceptStatus,
  acceptStatus,
  user,
  gatheringId,
  groupId,
  setGatheringStatusFn,
  isInPast,
}) => {
  const isAlreadyThisStatus = userAcceptStatus === acceptStatus;

  return (
    <ConditionalIconButton
      onClick={
        isAlreadyThisStatus || isInPast
          ? undefined
          : () =>
              setGatheringStatusFn({
                groupId,
                gatheringId,
                user,
              })
      }
    >
      <MobileFriendlyTooltip
        enterTouchDelay={200}
        title={getAcceptStatusTooltip(acceptStatus)}
      >
        <Icon
          className={cx({
            [classes.isInPast]: isInPast,
            [classes[acceptStatus]]: isAlreadyThisStatus,
          })}
        />
      </MobileFriendlyTooltip>
    </ConditionalIconButton>
  );
};

export default withStyles(styles)(AcceptStatusIconButton);
