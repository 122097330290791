import React, { FunctionComponent } from 'react';
import Page from '../Layout/Page';
import {
  Grid,
  Typography,
  WithStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core';
import GoToGatheringForm from '../Forms/GoToGatheringForm';

const styles = (theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing.unit * 4,
      textAlign: 'center',
    },
  });

const HomePage: FunctionComponent<WithStyles> = ({ classes }) => {
  return (
    <Page>
      <Grid container justify="center" alignItems="center" direction="column">
        <Typography variant="h2" className={classes.header}>
          Start Gathering
        </Typography>
        <GoToGatheringForm variant="filled" isBig />
      </Grid>
    </Page>
  );
};

export default withStyles(styles)(HomePage);
