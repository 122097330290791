import React, { FunctionComponent } from 'react';
import Gathering from './Gathering';
import { useGatherings } from '../../Context/gatherings';
import {
  Typography,
  CircularProgress,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: 50,
      width: '100%',
    },
    ul: {
      padding: 0,
      maxWidth: 720,
      width: '100%',
      margin: 0,

      '& li': {
        listStyle: 'none',

        '& + *': {
          marginTop: theme.spacing.unit * 4,
        },
      },
    },
  });

const Gatherings: FunctionComponent<WithStyles & { groupId: string }> = ({
  classes,
  groupId,
}) => {
  const { gatherings, getTodaysGatheringsAsync } = useGatherings();
  const gatheringsList = Object.values(gatherings);

  let content = (
    <ul className={classes.ul}>
      {gatheringsList.map(gathering => (
        <li key={`${gathering.title}${gathering.owner}`}>
          <Gathering groupId={groupId} gathering={gathering} />
        </li>
      ))}
    </ul>
  );

  if (getTodaysGatheringsAsync.isLoading) {
    content = (
      <CircularProgress
        variant="indeterminate"
        disableShrink
        size={24}
        thickness={4}
      />
    );
  } else if (gatheringsList.length === 0 && getTodaysGatheringsAsync.isLoaded) {
    content = (
      <Typography variant="h5" color="inherit">
        You have no gatherings for the day
      </Typography>
    );
  }

  return <div className={classes.container}>{content}</div>;
};

export default withStyles(styles)(Gatherings);
