import React, { FunctionComponent } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { TextField } from '@material-ui/core';

interface ReduxTextFieldProps extends WrappedFieldProps {
  label: string;
  type: string;
}

const ReduxTextField: FunctionComponent<ReduxTextFieldProps> = ({
  label,
  input,
  type,
  meta: { touched, error, warning },
  ...rest
}) => {
  console.log(error);
  return (
    <TextField
      {...rest}
      error={touched && Boolean(error)}
      label={label}
      type={type}
      {...input}
      margin="normal"
    />
  );
};

export default ReduxTextField;
