import React, { FunctionComponent, Fragment } from 'react';
import { compose } from 'recompose';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required } from './utils';
import ReduxTextField from './ReduxTextField';
import { Redirect } from 'react-router';
import IconSend from '@material-ui/icons/Send';
import {
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Grid,
} from '@material-ui/core';
import { useGatherings } from '../Context/gatherings';
import { useSignin } from '../Context/signin';

const styles = (theme: Theme) => createStyles({});

interface FormValues {
  commentText: string;
}

interface AddCommentFormProps {
  gatheringId: string;
  form: string;
}

const AddCommentForm: FunctionComponent<
  InjectedFormProps<FormValues> & WithStyles & AddCommentFormProps
> = ({ classes, handleSubmit, gatheringId, reset }) => {
  const { addComment } = useGatherings();
  const { isSignedIn, userInfo } = useSignin();

  if (!userInfo || !isSignedIn) {
    return <Redirect to="/signin" />;
  }

  return (
    <Fragment>
      <form
        noValidate
        onSubmit={handleSubmit(formValues => {
          const { commentText } = formValues;

          if (!commentText || !commentText.trim()) {
            reset();
            return Promise.resolve();
          }

          return addComment({
            comment: {
              text: commentText,
              user: userInfo,
            },
            gatheringId,
          }).then(() => reset());
        })}
      >
        <Grid container alignItems="center" className={classes.container}>
          <Field
            fullWidth
            name="commentText"
            type="text"
            placeholder="Start commenting!"
            autoComplete="off"
            component={ReduxTextField}
          />
        </Grid>
      </form>
    </Fragment>
  );
};

export default compose<
  InjectedFormProps<FormValues> & WithStyles & AddCommentFormProps,
  AddCommentFormProps
>(
  reduxForm({}),
  withStyles(styles),
)(AddCommentForm);
