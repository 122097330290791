import React, { SFC } from 'react';
import AppBar from '@material-ui/core/AppBar';
import {
  Typography,
  Toolbar,
  Button,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import { useSignin } from '../Context/signin';
import DeLink from '../display/DeLink';
import GoToGatheringForm from '../Forms/GoToGatheringForm';
import UserAvatar from '../Models/Users/UserAvatar';

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    signinContainer: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing.unit * 2,
      },
    },
    appBar: {
      backgroundColor: theme.palette.primary.main,
    },
    appName: {
      color: theme.palette.common.white,
    },
  });

const Header: SFC<WithStyles> = ({ classes }) => {
  const { userInfo, isSignedIn, signout } = useSignin();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <DeLink to="/">
          <Typography variant="h6" className={classes.appName}>
            Gatherify
          </Typography>
        </DeLink>
        {!isSignedIn && (
          <Button>
            <DeLink to="/signin">Signin</DeLink>
          </Button>
        )}
        {isSignedIn && userInfo && (
          <div className={classes.signinContainer}>
            <Button color="inherit" onClick={signout}>
              Sign Out
            </Button>
            <UserAvatar user={userInfo} />
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
