import React, { FunctionComponent } from 'react';
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  StyleRulesCallback,
} from '@material-ui/core';

const styles: StyleRulesCallback = theme =>
  createStyles({
    page: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,

      '@media only screen and (min-width: 1080px)': {
        maxWidth: 1080,
        margin: '0 auto',
      },

      [`@media only screen and (min-width: ${theme.breakpoints.values.md})`]: {
        padding: theme.spacing.unit * 4,
      },
    },
    header: {
      marginBottom: theme.spacing.unit * 4,
    },
  });

interface PageProps extends WithStyles<typeof styles> {
  title?: string;
}

const Page: FunctionComponent<PageProps> = ({ title, children, classes }) => (
  <div className={classes.page}>
    {title && (
      <Typography className={classes.header} variant="h4" color="inherit">
        {title}
      </Typography>
    )}
    {children}
  </div>
);

export default withStyles(styles)(Page);
