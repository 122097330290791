import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { IParticipant } from '../../../types';
import {
  Avatar,
  Grid,
  WithStyles,
  createStyles,
  Theme,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import Participant from './Participant';

const styles = (theme: Theme) =>
  createStyles({
    name: {
      marginLeft: theme.spacing.unit,
    },
    avatar: {
      border: `3px solid ${theme.palette.secondary}`,
      marginTop: theme.spacing.unit * 2,
    },
    ul: {
      padding: 0,
    },
    participantsContainer: {
      '& > * + *': {
        marginLeft: theme.spacing.unit * 2,
      },
    },
  });

interface ParticipantsProps extends WithStyles {
  participants: IParticipant[];
  className?: string;
}

const Participants: FunctionComponent<ParticipantsProps> = ({
  participants,
  classes,
  className,
}) => (
  <ul className={cx(className, classes.ul)}>
    <Grid
      container
      direction="row"
      justify="flex-start"
      className={classes.participantsContainer}
    >
      {participants.map(participant => {
        return (
          <Participant
            key={participant.id}
            participant={participant}
            className={classes.avatar}
          />
        );
      })}
    </Grid>
  </ul>
);

export default withStyles(styles)(Participants);
