import React, { SFC, useState, useEffect, useContext } from 'react';
import { createContext } from 'react';
import { addWeeks, isAfter } from 'date-fns';
import { IUser, LocalStorageState } from '../../types';

interface ISigninState {
  isSignedIn: boolean;
  userInfo?: IUser;
}

interface ISigninContext extends ISigninState {
  signin: (user: IUser) => void;
  signout: () => void;
}

const initialState = {
  isSignedIn: false,
  userInfo: undefined,
  signin: (user: IUser) => {
    console.log(user);
  },
  signout: () => {
    console.log('signout');
  },
};

const SigninContext = createContext<ISigninContext>(initialState);

const { Provider, Consumer } = SigninContext;

const useSigninContext = () => {
  const [signinState, setSigninState] = useState<ISigninState>(initialState);
  useEffect(() => {
    const stringifiedState = localStorage.getItem('scheduleYourShit');
    if (typeof stringifiedState === 'string') {
      const { savedTime, signinInfo }: LocalStorageState =
        (JSON.parse(stringifiedState) as LocalStorageState) || {};

      if (
        isAfter(addWeeks(savedTime, 1), new Date()) &&
        signinInfo &&
        signinInfo.isSignedIn
      ) {
        const { user, isSignedIn } = signinInfo;

        setSigninState({
          userInfo: user,
          isSignedIn: isSignedIn,
        });
      } else {
        localStorage.clear();
      }
    }
  }, []);

  return {
    ...signinState,
    signin: (user: IUser) => {
      setSigninState({
        isSignedIn: true,
        userInfo: user,
      });

      const saveState: LocalStorageState = {
        signinInfo: {
          isSignedIn: true,
          user,
        },
        savedTime: Date.now(),
      };

      localStorage.setItem('scheduleYourShit', JSON.stringify(saveState));
    },
    signout: () => {
      setSigninState({
        isSignedIn: false,
        userInfo: undefined,
      });

      localStorage.clear();
    },
  };
};

export const SigninProvider: SFC = ({ children }) => {
  const signinContext = useSigninContext();

  return <Provider value={signinContext}>{children}</Provider>;
};

export const useSignin = () => useContext(SigninContext);

export { Consumer };

export default SigninContext;
