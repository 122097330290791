import React, { FunctionComponent, useState, ReactNode } from 'react';
import { compose } from 'recompose';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import {
  Button,
  Grid,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { format, parse, addHours } from 'date-fns';
import { useGatherings } from '../Context/gatherings';
import { Redirect } from 'react-router';
import { required } from './utils';
import ReduxTextField from './ReduxTextField';

const styles = (theme: Theme) =>
  createStyles({
    field: {
      width: '100%',

      [`@media only screen and (min-width: ${
        theme.breakpoints.values.sm
      }px)`]: {
        maxWidth: theme.breakpoints.values.sm,
      },
    },

    submitButton: {
      [`@media only screen and (min-width: ${
        theme.breakpoints.values.sm
      }px)`]: {
        width: '100%',
      },
    },

    buttonContainer: {
      marginTop: theme.spacing.unit * 2,

      [`@media only screen and (min-width: ${
        theme.breakpoints.values.sm
      }px)`]: {
        maxWidth: theme.breakpoints.values.sm,
      },

      '& > * + *': {
        marginLeft: theme.spacing.unit * 2,
      },
    },
  });

interface IAddGatheringFormValues {
  gatheringDate: string;
  description?: string;
  title: string;
}

interface GatheringFormProps {
  groupId: string;
  cancelContent?: ReactNode;
}

const GatheringForm: FunctionComponent<
  GatheringFormProps & InjectedFormProps<IAddGatheringFormValues> & WithStyles
> = ({ handleSubmit, classes, groupId, cancelContent }) => {
  const { addGathering } = useGatherings();
  const [didSubmit, setDidSubmit] = useState(false);

  if (didSubmit) {
    return <Redirect to={`/gatherings/${groupId}`} />;
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(values => {
        const { gatheringDate, title, description } = values;

        return addGathering({
          gatheringDate: parse(gatheringDate).getTime(),
          title,
          description,
        }).then(() => {
          setDidSubmit(true);
        });
      })}
    >
      <Grid container direction="column" alignItems="baseline">
        <Field
          name="title"
          type="text"
          label="Title"
          required
          variant="filled"
          component={ReduxTextField}
          className={classes.field}
          validate={[required]}
        />
        <Field
          name="description"
          type="text"
          label="Description"
          multiline
          variant="filled"
          component={ReduxTextField}
          className={classes.field}
        />
        <Field
          name="gatheringDate"
          type="datetime-local"
          required
          label="When"
          variant="filled"
          component={ReduxTextField}
          className={classes.field}
          validate={[required]}
        />
        <Grid
          className={classes.buttonContainer}
          container
          alignItems="center"
          justify="flex-end"
        >
          {cancelContent}
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default compose<
  GatheringFormProps & InjectedFormProps<IAddGatheringFormValues> & WithStyles,
  GatheringFormProps
>(
  reduxForm({
    form: 'addGathering',
    initialValues: {
      gatheringDate: format(addHours(new Date(), 1), 'YYYY-MM-DDTHH:mm'),
    },
  }),
  withStyles(styles),
)(GatheringForm);
