import React, { FunctionComponent, Fragment } from 'react';
import cx from 'classnames';
import { compose } from 'recompose';
import { reduxForm, Field, InjectedFormProps, reset } from 'redux-form';
import { required } from './utils';
import ReduxTextField from './ReduxTextField';
import { withRouter, RouteComponentProps } from 'react-router';
import IconSend from '@material-ui/icons/Send';
import {
  Button,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
  Grid,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      '& > *': {
        margin: 0,

        '&:last-child': {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },

        '&:first-child': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    submit: {
      height: 56,
    },
    input: {
      '& > *:last-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        // backgroundColor: theme.palette.grey[100],
      },
    },
    bigSubmit: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      height: 84.2,
    },
    bigInput: {
      '& > *:first-child': {
        fontSize: '1.5rem',
      },

      '& > *:last-child': {
        fontSize: '2.5rem',
      },
    },
  });

interface FormValues {
  gatheringId: string;
}

interface GoToGatheringFormProps extends Pick<TextFieldProps, 'variant'> {
  isBig?: boolean;
}

const GoToGatheringForm: FunctionComponent<
  InjectedFormProps<FormValues> &
    WithStyles &
    RouteComponentProps &
    GoToGatheringFormProps
> = ({
  classes,
  handleSubmit,
  history,
  isBig = false,
  variant = 'filled',
  reset,
}) => {
  return (
    <Fragment>
      <form
        noValidate
        onSubmit={handleSubmit(formValues => {
          const { gatheringId } = formValues;

          if (gatheringId && gatheringId.trim()) {
            history.push(`/gatherings/${gatheringId}`);
          } else {
            reset();
          }
        })}
      >
        <Grid
          container
          alignItems="center"
          className={classes.container}
          wrap="nowrap"
        >
          <Field
            color="inherit"
            name="gatheringId"
            type="text"
            label="Your group's name"
            variant={variant}
            component={ReduxTextField}
            className={cx({ [classes.bigInput]: isBig }, classes.input)}
          />
          <Button
            variant="contained"
            color="secondary"
            className={cx({ [classes.bigSubmit]: isBig }, classes.submit)}
            type="submit"
          >
            <IconSend />
          </Button>
        </Grid>
      </form>
    </Fragment>
  );
};

export default compose<
  InjectedFormProps<FormValues> &
    WithStyles &
    RouteComponentProps &
    GoToGatheringFormProps,
  GoToGatheringFormProps
>(
  reduxForm({
    form: 'go-to-gathering',
  }),
  withStyles(styles),
  withRouter,
)(GoToGatheringForm);
