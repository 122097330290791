export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ArgumentTypes<F extends Function> = F extends (
  ...args: infer A
) => any
  ? A
  : never;

export interface IUser {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string;
}

export interface IComment extends ICommentDataEntry {
  id: string;
  user: IUser;
  text: string;
  createdDate: number;
}

export interface ICommentDataEntry
  extends Omit<IComment, 'id' | 'createdDate'> {}

export interface IGathering extends IGatheringDataEntry {
  id: string;
  createdDate: number;
  gatheringDate: number;
  groupId: string;
  title: string;
  description?: string;
  owner: IUser;
  participants: IParticipant[];
  comments: IComment[];
}

export interface DBGathering extends Omit<IGathering, 'comments'> {
  comments: {
    [key: string]: IComment;
  };
}

export interface IGatheringDataEntry extends Omit<IGathering, 'id'> {}

export interface IParticipant extends IUser {
  acceptStatus: AcceptStatus;
}

export enum AcceptStatus {
  Accept = 'Accept',
  Decline = 'Decline',
  Tentative = 'Tentative',
}

export interface LocalStorageState {
  signinInfo?: {
    user: IUser;
    isSignedIn: boolean;
  };
  savedTime: number;
}

export interface ReducerAction<T, P> {
  type: T;
  payload: P;
}

export interface ReducerActionWithId<T, P, I> extends ReducerAction<T, P> {
  id: I;
}
