import React, { FunctionComponent, Fragment } from 'react';
import cx from 'classnames';
import {
  Avatar,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import IconClear from '@material-ui/icons/Clear';
import IconDone from '@material-ui/icons/Done';
import IconHelp from '@material-ui/icons/Help';
import { IParticipant, AcceptStatus } from '../../../types';
import { colorSuccess } from '../../Theme';
import MobileFriendlyTooltip from '../../display/MobileFriendlyTooltip';

const styles = (theme: Theme) =>
  createStyles({
    declineIcon: {
      color: theme.palette.common.white,
      // position: 'absolute',
      // fontSize: 58,
      // top: -10,
      // left: -9,
      // zIndex: 10,
      position: 'absolute',
      fontSize: 20,
      top: 20,
      left: 22,
      zIndex: 10,
      borderRadius: 30,
      border: `2px solid ${theme.palette.common.white}`,
      backgroundColor: theme.palette.error.main,
    },
    acceptIcon: {
      color: theme.palette.common.white,
      position: 'absolute',
      fontSize: 20,
      top: 20,
      left: 22,
      zIndex: 10,
      borderRadius: 30,
      border: `2px solid ${theme.palette.common.white}`,
      backgroundColor: colorSuccess.main,
    },
    tentativeIcon: {
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      fontSize: 24,
      top: 20,
      left: 22,
      zIndex: 10,
      borderRadius: 30,
      // border: `2px solid ${theme.palette.common.white}`,
      color: theme.palette.primary.light,
    },
    container: {
      position: 'relative',
    },
  });

interface ParticipantProps {
  className?: string;
  participant: IParticipant;
}

const getAcceptText = (acceptStatus: AcceptStatus) => {
  switch (acceptStatus) {
    case AcceptStatus.Accept:
      return 'is attending';
    case AcceptStatus.Tentative:
      return 'is on the fence';
    case AcceptStatus.Decline:
    default:
      return 'is not attending';
  }
};

const Participant: FunctionComponent<ParticipantProps & WithStyles> = ({
  className,
  classes,
  participant: { name, profilePictureUrl, acceptStatus },
}) => {
  return (
    <MobileFriendlyTooltip title={`${name} ${getAcceptText(acceptStatus)}`}>
      <div className={cx(className, classes.container)}>
        {acceptStatus === AcceptStatus.Decline && (
          <IconClear className={classes.declineIcon} />
        )}
        {acceptStatus === AcceptStatus.Accept && (
          <IconDone className={classes.acceptIcon} />
        )}
        {acceptStatus === AcceptStatus.Tentative && (
          <IconHelp className={classes.tentativeIcon} />
        )}
        <Avatar
          className={classes[acceptStatus]}
          alt={name}
          src={profilePictureUrl}
        />
      </div>
    </MobileFriendlyTooltip>
  );
};

export default withStyles(styles)(Participant);
