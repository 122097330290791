import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { IComment } from '../../../types';
import UserAvatar from '../Users/UserAvatar';
import {
  WithStyles,
  Theme,
  createStyles,
  withStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { distanceInWordsToNow } from 'date-fns';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    commentInfoContainer: {
      display: 'flex',
      flexDirection: 'column',

      [`@media only screen and (min-width: ${
        theme.breakpoints.values.md
      }px)`]: {
        maxWidth: '75%',
      },

      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit,
    },
    time: {
      marginTop: theme.spacing.unit * 2,
    },
    commentAvatar: {
      marginLeft: -10,
      width: theme.spacing.unit * 3,
      height: theme.spacing.unit * 3,
    },
  });

interface CommentProps {
  comment: IComment;
  className?: string;
}

const Comment: FunctionComponent<CommentProps & WithStyles> = ({
  classes,
  className,
  comment: { createdDate, text, user },
}) => {
  return (
    <div className={cx(className, classes.container)}>
      <Paper className={classes.commentInfoContainer} elevation={0}>
        <div>{text}</div>
        <Typography className={classes.time} variant="caption">
          {distanceInWordsToNow(createdDate)} ago
        </Typography>
      </Paper>
      <UserAvatar className={classes.commentAvatar} user={user} />
    </div>
  );
};

export default withStyles(styles)(Comment);
