import React, { SFC, Fragment } from 'react';
import LoginButton from '../LoginButton';
import Page from '../Layout/Page';
import { useSignin } from '../Context/signin';
import { Button } from '@material-ui/core';

const LoginPage: SFC = () => {
  const { isSignedIn, userInfo, signout } = useSignin();

  return (
    <Page title="Sign in">
      {!isSignedIn && <LoginButton />}
      {isSignedIn && (
        <Fragment>
          <p>
            {userInfo
              ? `You're already signed in, ${userInfo.firstName}!`
              : "You're already signed in!"}
          </p>
          <Button onClick={signout} color="secondary" variant="contained">
            Sign out
          </Button>
        </Fragment>
      )}
    </Page>
  );
};

export default LoginPage;
