import React, { SFC } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3d6bb3',
    },
    secondary: {
      main: '#a5867c',
    },
    error: {
      main: '#D21F3C',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const colorSuccess = {
  light: green[300],
  main: green[500],
  dark: green[700],
};

const Theme: SFC = ({ children }) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);

export default Theme;
