import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  IGatheringDataEntry,
  IGathering,
  AcceptStatus,
  IParticipant,
  IUser,
  IComment,
  ICommentDataEntry,
  DBGathering,
  Omit,
} from '../types';
import { isToday, isAfter } from 'date-fns';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyCW0eGON2QjpSs--PwX0YsDtSPaYijNSUk',
  authDomain: 'authDomain',
  projectId: 'second-scheduler',
});

const db = app.firestore();

const transformDBCommentsToClientShape = (comments: {
  [key: string]: IComment;
}) =>
  Object.entries(comments)
    .map(([id, comment]) => ({
      id,
      ...comment,
    }))
    .sort((a, b) => a.createdDate - b.createdDate);

const transformDBToClientShape = (gathering: DBGathering): IGathering => {
  return {
    ...gathering,
    comments: transformDBCommentsToClientShape(gathering.comments),
  };
};

const transformClientToDBShape = (gathering: IGathering): DBGathering => {
  return {
    ...gathering,
    comments: gathering.comments.reduce(
      (map, comment) => ({ ...map, [comment.id]: comment }),
      {},
    ),
  };
};

const cleanObj = <T>(obj: T) =>
  Object.entries(obj)
    .filter(([key, value]) => value !== undefined)
    .reduce(
      (newObj, [key, value]) => ({
        ...newObj,
        [key]: value,
      }),
      {},
    );

export const addGathering = (gathering: IGatheringDataEntry) => {
  const cleanGathering = cleanObj<IGatheringDataEntry>({
    ...gathering,
    participants: [
      {
        ...gathering.owner,
        acceptStatus: AcceptStatus.Accept,
      },
    ].concat(gathering.participants),
  }) as IGathering;

  return db
    .collection(gathering.groupId)
    .add(transformClientToDBShape(cleanGathering))
    .then(res => ({ ...cleanGathering, id: res.id }));
};

export const getGatheringsByGroupId = (
  groupId: string,
): Promise<IGathering[]> => {
  return db
    .collection(groupId)
    .get()
    .then(querySnapshot => {
      let results: IGathering[] = [];
      querySnapshot.forEach(res => {
        const gathering = {
          ...(res.data() as Omit<DBGathering, 'id'>),
          id: res.id,
        };
        results.push(transformDBToClientShape(gathering));
      });

      return results;
    });
};

export const getTodaysGatheringsByGroupId = (groupId: string) => {
  return getGatheringsByGroupId(groupId).then(gatherings =>
    gatherings.filter(
      ({ gatheringDate }) =>
        isAfter(gatheringDate, new Date()) || isToday(gatheringDate),
    ),
  );
};

interface SetGatheringAcceptStatusArgs {
  user: IUser;
  groupId: string;
  gatheringId: string;
}

export const createGatheringAcceptStatus = (
  acceptStatus: AcceptStatus,
) => async ({ groupId, gatheringId, user }: SetGatheringAcceptStatusArgs) => {
  const gatheringDoc = db.collection(groupId).doc(gatheringId);
  const querySnapshot = await gatheringDoc.get();
  const { participants } = querySnapshot.data() as IGathering;
  const userIndex = participants.findIndex(
    (participant: IParticipant) => user.id === participant.id,
  );
  let updatedParticipants = participants;
  if (userIndex === -1) {
    updatedParticipants = participants.concat({
      ...user,
      acceptStatus,
    });
  } else {
    updatedParticipants = participants
      .slice(0, userIndex)
      .concat({ ...user, acceptStatus }, participants.slice(userIndex + 1));
  }

  await gatheringDoc.update({ participants: updatedParticipants });

  return updatedParticipants;
};

export const acceptGathering = createGatheringAcceptStatus(AcceptStatus.Accept);
export const declineGathering = createGatheringAcceptStatus(
  AcceptStatus.Decline,
);
export const tentativelyAcceptGathering = createGatheringAcceptStatus(
  AcceptStatus.Tentative,
);

export interface AddCommentArgs {
  groupId: string;
  gatheringId: string;
  comment: ICommentDataEntry;
}

const generateCommentId = (userId: string) => `${userId}-${Date.now()}`;

export const addComment = async ({
  groupId,
  gatheringId,
  comment,
}: AddCommentArgs): Promise<IComment[]> => {
  const gatheringDoc = db.collection(groupId).doc(gatheringId);
  const querySnapshot = await gatheringDoc.get();
  const { comments } = querySnapshot.data() as DBGathering;
  const newCommentId = generateCommentId(comment.user.id);

  const updatedComments = {
    ...comments,
    [newCommentId]: { ...comment, id: newCommentId, createdDate: Date.now() },
  };

  await gatheringDoc.update({
    comments: updatedComments,
  });

  return transformDBCommentsToClientShape(updatedComments);
};
