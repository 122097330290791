import React, { FunctionComponent, useState } from 'react';
import cx from 'classnames';
import {
  Collapse,
  Grid,
  IconButton,
  WithStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import IconExpandMore from '@material-ui/icons/ExpandMore';

const styles = (theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    isOpen: {
      transform: 'rotate(180deg)',
    },
    clickable: {
      alignSelf: 'center',
      marginBottom: theme.spacing.unit * -3,
    },
  });

const Drawer: FunctionComponent<
  { initialIsOpenState?: boolean } & WithStyles
> = ({ classes, children, initialIsOpenState = false }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpenState);

  return (
    <Grid container direction="column">
      <Grid item className={classes.clickable}>
        <IconButton
          className={cx(classes.expand, { [classes.isOpen]: isOpen })}
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
        >
          <IconExpandMore />
        </IconButton>
      </Grid>
      <Grid item>
        <Collapse in={isOpen} timeout="auto">
          {children}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Drawer);
