import React, {
  createContext,
  SFC,
  useEffect,
  useContext,
  useReducer,
  Reducer,
} from 'react';
import {
  addGathering,
  getTodaysGatheringsByGroupId,
  acceptGathering,
  declineGathering,
  tentativelyAcceptGathering,
  addComment,
  AddCommentArgs,
} from '../../services/gatherings';
import AsyncState from '../../models/AsyncState';
import useAsyncState from '../../hooks/useAsyncState';
import {
  IGathering,
  IUser,
  ReducerAction,
  IParticipant,
  ReducerActionWithId,
  IComment,
  Omit,
} from '../../types';
import { useSignin } from './signin';

interface IGatheringMap {
  [key: string]: IGathering;
}

interface IGatheringsContext {
  gatherings: IGatheringMap;
  addGathering: (
    gathering: Pick<IGathering, 'gatheringDate' | 'title' | 'description'>,
  ) => Promise<void>;
  addGatheringAsync: AsyncState;
  addComment: (args: Omit<AddCommentArgs, 'groupId'>) => Promise<IComment[]>;
  acceptGathering: typeof acceptGathering;
  declineGathering: typeof declineGathering;
  tentativelyAcceptGathering: typeof tentativelyAcceptGathering;
  getTodaysGatherings: () => void;
  getTodaysGatheringsAsync: AsyncState;
}

const statusFn = () => {
  return Promise.resolve([]);
};

const initialState: IGatheringsContext = {
  gatherings: {},
  addGathering: async v => {
    console.log(v);
  },
  addGatheringAsync: new AsyncState(),
  addComment: statusFn,
  getTodaysGatherings: () => {},
  acceptGathering: statusFn,
  declineGathering: statusFn,
  tentativelyAcceptGathering: statusFn,
  getTodaysGatheringsAsync: new AsyncState(),
};

const GatheringsContext = createContext<IGatheringsContext>(initialState);

const { Provider, Consumer } = GatheringsContext;

const initialGatheringsState = {};

type GatheringAction =
  | ReducerAction<'SET', IGatheringMap>
  | ReducerActionWithId<'ADD', IGathering, string>
  | ReducerActionWithId<'UPDATE_PARTICIPANTS', IParticipant[], string>
  | ReducerActionWithId<'UPDATE_COMMENTS', IComment[], string>;

const gatheringsReducer: Reducer<IGatheringMap, GatheringAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'SET':
      return action.payload;
    case 'ADD':
      return {
        ...state,
        [action.id]: action.payload,
      };
    case 'UPDATE_PARTICIPANTS':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          participants: action.payload,
        },
      };
    case 'UPDATE_COMMENTS':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          comments: action.payload,
        },
      };
    default:
      throw new Error(`unknown action ${action}`);
  }
};

const useGatheringsContext = (groupId: string): IGatheringsContext => {
  const { userInfo } = useSignin();
  const [gatherings, dispatch] = useReducer(
    gatheringsReducer,
    initialGatheringsState,
  );
  const getTodaysGatheringsAsync = useAsyncState();
  const addGatheringAsync = useAsyncState();

  return {
    gatherings,
    addGathering: addGatheringValues => {
      addGatheringAsync.setLoading();
      const gathering = {
        ...addGatheringValues,
        createdDate: Date.now(),
        groupId,
        owner: userInfo as IUser,
        participants: [],
        comments: [],
      };

      return addGathering(gathering)
        .then(createdGathering => {
          dispatch({
            type: 'ADD',
            payload: createdGathering,
            id: createdGathering.id,
          });
          addGatheringAsync.setLoaded();
        })
        .catch(error => {
          addGatheringAsync.setError(error);
        });
    },
    addGatheringAsync: addGatheringAsync.asyncState,
    addComment: async args => {
      const newComments = await addComment({
        ...args,
        groupId,
      });
      dispatch({
        type: 'UPDATE_COMMENTS',
        id: args.gatheringId,
        payload: newComments,
      });
      return newComments;
    },
    acceptGathering: async args => {
      const newParticipants = await acceptGathering(args);
      dispatch({
        type: 'UPDATE_PARTICIPANTS',
        id: args.gatheringId,
        payload: newParticipants,
      });

      return newParticipants;
    },
    declineGathering: async args => {
      const newParticipants = await declineGathering(args);
      dispatch({
        type: 'UPDATE_PARTICIPANTS',
        id: args.gatheringId,
        payload: newParticipants,
      });

      return newParticipants;
    },
    tentativelyAcceptGathering: async args => {
      const newParticipants = await tentativelyAcceptGathering(args);
      dispatch({
        type: 'UPDATE_PARTICIPANTS',
        id: args.gatheringId,
        payload: newParticipants,
      });

      return newParticipants;
    },
    getTodaysGatherings: () => {
      getTodaysGatheringsAsync.setLoading();

      getTodaysGatheringsByGroupId(groupId).then(todaysGatherings => {
        getTodaysGatheringsAsync.setLoaded();
        dispatch({
          type: 'SET',
          payload: todaysGatherings.reduce(
            (gatheringMap, gathering) => ({
              ...gatheringMap,
              [gathering.id]: gathering,
            }),
            {},
          ),
        });
      });
    },
    getTodaysGatheringsAsync: getTodaysGatheringsAsync.asyncState,
  };
};

interface GatheringsProviderProps {
  groupId: string;
}

export const GatheringsProvider: SFC<GatheringsProviderProps> = ({
  children,
  groupId,
}) => {
  const gatheringsContext = useGatheringsContext(groupId);
  useEffect(() => {
    gatheringsContext.getTodaysGatherings();
  }, [groupId]);

  return <Provider value={gatheringsContext}>{children}</Provider>;
};

export const useGatherings = () => useContext(GatheringsContext);

export { Consumer };

export default GatheringsContext;
