class AsyncState {
  public isLoading: boolean;
  public isLoaded: boolean;
  public error?: Error;

  constructor() {
    this.isLoaded = false;
    this.isLoading = false;
  }
}

export default AsyncState;
