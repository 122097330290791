import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from '../store';
import Header from './Layout/Header';
import Theme from './Theme';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './Pages/LoginPage';
import GatheringsPage from './Pages/GatheringsPage';
import { SigninProvider } from './Context/signin';
import { GatheringsProvider } from './Context/gatherings';
import AddGatheringPage from './Pages/AddGathering';
import HomePage from './Pages/HomePage';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <SigninProvider>
          <Router>
            <Theme>
              <Header />
              <Route exact path="/" component={HomePage} />
              <Route
                path="/gatherings/:groupId"
                render={({ match, ...rest }) => {
                  const { groupId } = match.params;

                  return (
                    <GatheringsProvider groupId={match.params.groupId}>
                      <Route
                        path="/gatherings/:groupId"
                        exact
                        render={() => <GatheringsPage groupId={groupId} />}
                      />
                      <Route
                        path="/gatherings/:groupId/add"
                        exact
                        render={props => (
                          <AddGatheringPage groupId={groupId} {...props} />
                        )}
                      />
                    </GatheringsProvider>
                  );
                }}
              />
              <Route path="/signin" component={LoginPage} />
            </Theme>
          </Router>
        </SigninProvider>
      </Provider>
    );
  }
}

export default App;
