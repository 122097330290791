import React, { FunctionComponent } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import GatheringForm from '../Forms/GatheringForm';
import Page from '../Layout/Page';
import {
  Link,
  WithStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import { useSignin } from '../Context/signin';

const styles = (theme: Theme) =>
  createStyles({
    backLink: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  });

interface AddGatheringPageProps {
  groupId: string;
}

const AddGatheringPage: FunctionComponent<
  AddGatheringPageProps & RouteComponentProps & WithStyles
> = ({ groupId, history, classes }) => {
  const { isSignedIn } = useSignin();

  if (!isSignedIn) {
    return <Redirect to="/signin" />;
  }

  return (
    <Page title="Create a gathering">
      <GatheringForm
        groupId={groupId}
        cancelContent={
          <Link className={classes.backLink} onClick={() => history.goBack()}>
            Go Back
          </Link>
        }
      />
    </Page>
  );
};

export default withStyles(styles)(withRouter(AddGatheringPage));
