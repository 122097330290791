import React, { FunctionComponent } from 'react';
import { IUser } from '../../../types';
import { Avatar } from '@material-ui/core';
import MobileFriendlyTooltip from '../../display/MobileFriendlyTooltip';

interface UserAvatarProps {
  user: IUser;
  title?: string;
  className?: string;
}

const UserAvatar: FunctionComponent<UserAvatarProps> = ({
  user,
  title,
  className,
}) => {
  return (
    <MobileFriendlyTooltip title={title || user.name}>
      <Avatar
        className={className}
        alt={user.name}
        src={user.profilePictureUrl}
      />
    </MobileFriendlyTooltip>
  );
};

export default UserAvatar;
