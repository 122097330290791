import React, { FunctionComponent, useState } from 'react';
import cx from 'classnames';
import { IGathering, AcceptStatus } from '../../../types';
import Participants from '../Participants/Participants';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Button,
} from '@material-ui/core';
import IconThumbUp from '@material-ui/icons/ThumbUp';
import IconThumbsUpDown from '@material-ui/icons/ThumbsUpDown';
import IconThumbDown from '@material-ui/icons/ThumbDown';
import { format, isToday, distanceInWordsToNow, isPast } from 'date-fns';
import { useSignin } from '../../Context/signin';
import { useGatherings } from '../../Context/gatherings';
import AcceptStatusIconButton from './AcceptStatusIconButton';
import useIntervalRerender from '../../../hooks/useIntervalRerender';
import Drawer from '../../display/Drawer';
import Comments from '../Comments/Comments';
import AddCommentForm from '../../Forms/AddCommentForm';

const styles = (theme: Theme) =>
  createStyles({
    infoContainer: {
      height: '100%',
    },
    timeContainer: {
      flexGrow: 100,
    },
    timeText: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    overText: {
      color: theme.palette.error.light,
    },
    commentButton: {
      marginTop: theme.spacing.unit * 2,
      // color: theme.palette.primary.dark,
    },
  });

const Gathering: FunctionComponent<
  { gathering: IGathering; groupId: string } & WithStyles
> = ({
  gathering: { title, participants, description, gatheringDate, comments, id },
  groupId,
  classes,
}) => {
  useIntervalRerender(60);
  const [isCommentsFormShow, showCommentsForm] = useState(false);
  const { userInfo, isSignedIn } = useSignin();
  const {
    acceptGathering,
    declineGathering,
    tentativelyAcceptGathering,
  } = useGatherings();
  const isInPast = isPast(gatheringDate);
  const formattedTime = format(gatheringDate, 'hh:mm a');

  let userAcceptStatus;
  if (userInfo) {
    const participantInfoOfUser = participants.find(
      participant => participant.id === userInfo.id,
    );
    userAcceptStatus = participantInfoOfUser
      ? participantInfoOfUser.acceptStatus
      : undefined;
  }

  return (
    <Card raised>
      <CardContent>
        <Grid container direction="column">
          <Grid item>
            <Grid direction="row" container justify="space-between">
              <Grid item xs={9}>
                <Grid
                  container
                  direction="column"
                  className={classes.infoContainer}
                >
                  <Typography variant="h5" paragraph>
                    {title}
                  </Typography>
                  {description && (
                    <Typography paragraph variant="body2" color="textSecondary">
                      {description}
                    </Typography>
                  )}
                  <Typography variant="body1" className={classes.timeContainer}>
                    {`${
                      isToday(gatheringDate)
                        ? 'Today'
                        : format(gatheringDate, 'MMM Do')
                    } at ${formattedTime}`}
                    <span
                      className={cx(classes.timeText, {
                        [classes.overText]: isInPast,
                      })}
                    >
                      {' '}
                      (
                      {isInPast
                        ? 'over'
                        : `in ${distanceInWordsToNow(gatheringDate)}`}
                      )
                    </span>
                  </Typography>
                  <Participants participants={participants} />
                </Grid>
              </Grid>
              {userInfo && isSignedIn && (
                <Grid item>
                  <Grid container direction="column">
                    <AcceptStatusIconButton
                      isInPast={isInPast}
                      groupId={groupId}
                      gatheringId={id}
                      userAcceptStatus={userAcceptStatus}
                      user={userInfo}
                      Icon={IconThumbUp}
                      acceptStatus={AcceptStatus.Accept}
                      setGatheringStatusFn={acceptGathering}
                    />
                    <AcceptStatusIconButton
                      isInPast={isInPast}
                      groupId={groupId}
                      gatheringId={id}
                      userAcceptStatus={userAcceptStatus}
                      user={userInfo}
                      Icon={IconThumbsUpDown}
                      acceptStatus={AcceptStatus.Tentative}
                      setGatheringStatusFn={tentativelyAcceptGathering}
                    />
                    <AcceptStatusIconButton
                      isInPast={isInPast}
                      groupId={groupId}
                      gatheringId={id}
                      userAcceptStatus={userAcceptStatus}
                      user={userInfo}
                      Icon={IconThumbDown}
                      acceptStatus={AcceptStatus.Decline}
                      setGatheringStatusFn={declineGathering}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {comments.length > 0 ? (
            <Drawer>
              <Comments gatheringId={id} comments={comments} />
            </Drawer>
          ) : isCommentsFormShow ? (
            <AddCommentForm form={`add-comment-${id}`} gatheringId={id} />
          ) : (
            <Button
              className={classes.commentButton}
              size="small"
              color="secondary"
              onClick={() => showCommentsForm(true)}
            >
              Start Commenting
            </Button>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Gathering);
