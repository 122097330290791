import React, { FunctionComponent } from 'react';
import Page from '../Layout/Page';
import Gatherings from '../Models/Gatherings/Gatherings';
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Fab,
  Zoom,
} from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import IconAdd from '@material-ui/icons/Add';
import { withRouter, RouteComponentProps } from 'react-router';
import Draggable from 'react-draggable';

const styles = (theme: Theme) =>
  createStyles({
    addButton: {
      [`@media only screen and (min-width: ${
        theme.breakpoints.values.md
      }px)`]: {
        marginTop: theme.spacing.unit * 4,
        alignSelf: 'flex-end',
      },
      [`@media only screen and (max-width: ${theme.breakpoints.values.md -
        1}px)`]: {
        position: 'fixed',
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
      },
    },
  });

interface GatheringsPageProps {
  groupId: string;
}

const GatheringsPage: FunctionComponent<
  WithStyles & GatheringsPageProps & RouteComponentProps
> = ({ classes, groupId, history }) => {
  return (
    <Page title="Your Gatherings">
      <Gatherings groupId={groupId} />
      <Zoom in={true}>
        <Draggable>
          <Fab
            onClick={() => history.push(`/gatherings/${groupId}/add`)}
            color="primary"
            className={classes.addButton}
          >
            <IconAdd />
          </Fab>
        </Draggable>
      </Zoom>
    </Page>
  );
};

export default withStyles(styles)(withRouter(GatheringsPage));
