import React, { SFC } from 'react';
import GoogleLogin, {
  GoogleLoginResponseOffline,
  GoogleLoginResponse,
} from 'react-google-login';
import { useSignin } from './Context/signin';

const isOffline = (
  googleLoginRes: GoogleLoginResponseOffline | GoogleLoginResponse,
): googleLoginRes is GoogleLoginResponseOffline => {
  return Boolean((googleLoginRes as GoogleLoginResponseOffline).code);
};

const isOnline = (
  googleLoginRes: GoogleLoginResponseOffline | GoogleLoginResponse,
): googleLoginRes is GoogleLoginResponse => {
  return Boolean((googleLoginRes as GoogleLoginResponse).isSignedIn);
};

const LoginButton: SFC = () => {
  const { signin } = useSignin();

  return (
    <GoogleLogin
      onSuccess={googleLoginRes => {
        if ((googleLoginRes as GoogleLoginResponseOffline).code) {
        } else {
          if (isOffline(googleLoginRes)) {
            console.error('offline', googleLoginRes);
          } else if (isOnline(googleLoginRes)) {
            const profile = googleLoginRes.getBasicProfile();
            const profileInfo = {
              id: googleLoginRes.getId(),
              firstName: profile.getGivenName(),
              lastName: profile.getFamilyName(),
              profilePictureUrl: profile.getImageUrl(),
              email: profile.getEmail(),
              name: profile.getName(),
            };

            signin(profileInfo);
          }
        }
      }}
      onFailure={err => console.log(err)}
      clientId="798897190996-s7j5va56kvcbhkq8mes9ucftj5h89li9.apps.googleusercontent.com"
    />
  );
};

export default LoginButton;
