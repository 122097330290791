import React, { FunctionComponent, MouseEventHandler, Fragment } from 'react';
import {
  IconButton,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';

const styles = createStyles({
  plainIcon: {
    padding: 12,
    display: 'flex',
  },
});

const ConditionalIconButton: FunctionComponent<
  {
    onClick?: MouseEventHandler;
  } & WithStyles
> = ({ children, onClick, classes }) => {
  if (!onClick) {
    return <div className={classes.plainIcon}>{children}</div>;
  }

  return <IconButton onClick={onClick}>{children}</IconButton>;
};

export default withStyles(styles)(ConditionalIconButton);
