import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import { WithStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    deLinked: {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  });

interface DeLinkProps extends WithStyles, LinkProps {}

const DeLink: FunctionComponent<DeLinkProps> = ({
  classes,
  className,
  ...props
}) => <Link className={cx(classes.deLinked, className)} {...props} />;

export default withStyles(styles)(DeLink);
