import { useState, useEffect } from 'react';

const useIntervalRerender = (intervalInSeconds: number) => {
  const [, rerender] = useState(Date.now);
  useEffect(() => {
    const interval = setInterval(
      () => rerender(Date.now),
      intervalInSeconds * 1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useIntervalRerender;
